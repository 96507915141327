<template>
    <div class="d-flex flex-column flex-root">
        <div
                class="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
                :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
        >
            <!-- begin:: Content -->
            <div
                    class="container d-flex flex-row-fluid flex-column justify-content-md-center p-12"
            >
                <h1
                        class="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12"
                >
                    401
                </h1>
                <p class="font-weight-boldest display-4">
                    Sorry ! You are UnAuthorized for this action
                </p>
                <p class="font-size-h3">
                   Please Contact Adminstrator
<!--                    <br />as soon possible. <br />You can back or use our Help Center.-->
                </p>
            </div>
            <!-- end:: Content -->
        </div>
    </div>
</template>

<style lang="scss">
    @import "@/assets/sass/pages/error/error-5.scss";
</style>

<script>
    export default {
        name: "u",
        mounted() {},
        computed: {
            backgroundImage() {
                return process.env.BASE_URL + "media/error/bg5.jpg";
            }
        }
    };
</script>
